import React from "react";
import { Box, IconButton, Typography, Popover } from "@material-ui/core";
import useStyles from "./styles";
import CalendarIcon from "media/calendar-icon.svg";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SettingsIcon from "@material-ui/icons/Settings";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import clsx from "clsx";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import history from "history.js"
import { useTranslation } from "react-i18next";

const DashboardHeader = ({
  plotName,
  crops,
  selectedDateRange,
  selectDate,
  toggleAddWidgetDrawer,
  plotId,
  refresh
}) => {
  const { t } = useTranslation();

  const {
    container,
    plotData,
    plotNameStyle,
    cropsName,
    selectDateRange,
    selectDateBtn,
    dateRange,
    rightBox,
    actionsBtn,
    addWidgetBtn,
    datepickerContent,
    timeBtn,
    timeBtnSelected,
    pickerRow,
  } = useStyles();

  const [calendarAnchorEl, setCalendarAnchorEl] = React.useState(null);

  const handleCalendarOpen = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };
  const handleCalendarClose = () => {
    setCalendarAnchorEl(null);
  };

  const handleGoToPlotSettings = () => {
    history.push(`/plot-settings/${plotId}`)
  }

  return (
    <Box className={container}>
      <Box className={plotData}>
        <Typography className={plotNameStyle}>{plotName}</Typography>
        <Typography className={cropsName}>{crops.join(",")}</Typography>
      </Box>
      <Box className={rightBox}>
        <Box className={selectDateBtn} onClick={handleCalendarOpen}>
          <img src={CalendarIcon} alt="calendar" />
          <Typography className={dateRange}>
            {selectedDateRange[0].format("DD.MM.YYYY")} -{" "}
            {selectedDateRange[1].format("DD.MM.YYYY")}
          </Typography>
        </Box>
        <IconButton className={actionsBtn} onClick={refresh}>
          <AutorenewIcon />
        </IconButton>
        <IconButton style={{ display: "none" }} className={actionsBtn}>
          <PlayArrowIcon />
        </IconButton>
        <IconButton onClick={handleGoToPlotSettings} className={actionsBtn}>
          <SettingsIcon />
        </IconButton>
        <IconButton
          onClick={() => toggleAddWidgetDrawer(true)}
          className={clsx(actionsBtn, addWidgetBtn)}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(calendarAnchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={calendarAnchorEl}
        onClose={handleCalendarClose}
      >
        <Box className={datepickerContent}>
          <DateRangePicker
            onApply={(e, data) => {
              if (
                data.endDate.format("DD.MM.YYYY") ===
                moment().format("DD.MM.YYYY")
              ) {
                selectDate([data.startDate, moment()]);
              } else {
                selectDate([data.startDate, data.endDate]);
              }
              handleCalendarClose();
            }}
            initialSettings={{
              startDate: selectedDateRange[0],
              endDate: selectedDateRange[1],
            }}
          >
            <Box className={pickerRow}>
              <Box className={selectDateRange}>
                <img src={CalendarIcon} alt="calendar" />
                <Typography className={dateRange}>
                  {selectedDateRange[0].format("DD.MM.YYYY")}
                </Typography>
              </Box>
              -
              <Box className={selectDateRange}>
                <img src={CalendarIcon} alt="calendar" />
                <Typography className={dateRange}>
                  {selectedDateRange[1].format("DD.MM.YYYY")}
                </Typography>
              </Box>
            </Box>
          </DateRangePicker>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment().format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment().startOf("day"),
                moment(),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("today")}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment()
                  .subtract(1, "days")
                  .startOf("day")
                  .format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment()
                  .subtract(1, "days")
                  .startOf("day"),
                moment()
                  .subtract(1, "days")
                  .endOf("day"),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("yesterday")}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment()
                  .subtract(7, "days")
                  .format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment().subtract(7, "days"),
                moment(),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("showLastDays", {daysCount: 7})}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment()
                  .subtract(30, "days")
                  .format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment().subtract(30, "days"),
                moment(),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("showLastDays", {daysCount: 30})}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment()
                  .subtract(60, "days")
                  .format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment().subtract(60, "days"),
                moment(),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("showLastDays", {daysCount: 60})}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment()
                  .subtract(6, "months")
                  .format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment().subtract(6, "months"),
                moment(),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("showLast6Months")}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]:
                selectedDateRange[0].format("DD.MM.YYYY") ===
                moment()
                  .subtract(1, "year")
                  .format("DD.MM.YYYY"),
            })}
            onClick={() => {
              selectDate([
                moment().subtract(1, "year"),
                moment(),
              ]);
              handleCalendarClose();
            }}
          >
            <Typography>{t("showLastYear")}</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DashboardHeader;

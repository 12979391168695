import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { AppLoader } from "components/commons";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SENSOR_TO_DEPTH = {
  "start-rt-cent": "startDepth",
  "stop-rt-cent": "stopDepth",
};

const UNITS_BY_SERIES_INDEX_MOTTECH = {
  0: "cb",
  1: "cb",
  2: "mm",
};

const UNITS_BY_SERIES_INDEX_TALGIL = {
  0: "cb",
  1: "cb",
  2: "㎥",
};

const VirtualDeviceWpGraph = ({
  virtualDeviceWp,
  depths,
  daterange,
  irrigationHistory,
  withScroll = false,
}) => {
  const [chartData, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    let data = [];
    let irrigationData = [];
    if (irrigationHistory && irrigationHistory.irrigation) {
      irrigationData = irrigationHistory.irrigation.values.map((item) => [
        item[0],
        undefined,
        undefined,
        +item[1].toFixed(2),
      ]);
    }
    if (virtualDeviceWp) {
      data = [
        ...irrigationData,
        ...virtualDeviceWp.data.map((item) => [item[0], item[1], item[2]]),
      ];
    }

    setData(data);
  }, [virtualDeviceWp, irrigationHistory]);
  if (chartData) {
    return (
      <ReactEcharts
        style={{ height: "100%" }}
        option={{
          tooltip: {
            trigger: "axis",
            appendToBody: true,
            formatter: (data) => {
              if (!data.length) {
                data = [data];
              }
              let tooltip = `${moment(data[0].value[data[0].encode.x]).format(
                "DD-MM YYYY HH:mm"
              )} <br/>`;
              data.forEach(
                ({ value, marker, seriesName, seriesIndex, encode: { y } }) => {
                  if (value[y] !== undefined) {
                    tooltip += `<div style="display: flex; align-items: center; direction: ltr;">${marker}<div style="padding-inline-end: 5px">${seriesName}:</div><div style="direction: ltr">${
                      value[y] === null || value[y] === undefined
                        ? "-"
                        : value[y]
                    }${
                      irrigationHistory &&
                      irrigationHistory.irrigation &&
                      irrigationHistory.irrigation.columns.includes(
                        "irrigationVolume"
                      )
                        ? UNITS_BY_SERIES_INDEX_TALGIL[seriesIndex]
                        : UNITS_BY_SERIES_INDEX_MOTTECH[seriesIndex]
                    }</div></div>`;
                  }
                }
              );
              return tooltip;
            },
          },
          axisLabel: { hideOverlap: true },
          grid: {
            top: "50",
            left: "50",
            right: "50",
            show: true,
          },
          color: ["#FF3B30", "#70b8e1", "rgba(199, 205, 240, 1)"],
          legend: {},
          dataZoom: withScroll && [
            {
              type: "inside",
              realtime: true,
              start: 0,
              end: 100,
            },
          ],
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataZoom: {
                show: true,
                title: { zoom: t("zoomTooltip"), back: t("back") },
              },
              restore: { show: true, title: t("restore") },
              saveAsImage: { show: true, title: t("saveAsImage") },
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            min: daterange[0].valueOf(),
            max: daterange[1].valueOf(),
            axisLabel: {
              formatter: function (value) {
                return moment(value).format("DD-MM YYYY");
              },
            },
          },
          yAxis: [
            {
              type: "value",
              position: "right",
              min: (value) => {
                return value.min - 5;
              },
              max: 0,
              name: t("waterPotentialYAxisTitle"),
              nameLocation: "center",
              nameTextStyle: {
                color: "#828B93",
                fontSize: "12px",
              },
              nameGap: 30,
            },
            {
              type: "value",
              show: false,
              min: 0.5,
            },
            {
              type: "value",
              show: false,
              min: 0.5,
            },
          ],
          dataset: {
            source: chartData.sort((a, b) => b[0] - a[0]),
            dimensions: [
              "timestamp",
              "start-rt-cent",
              "stop-rt-cent",
              "irrigation",
            ],
          },
          series: [
            {
              name: `(1)${Math.round(
                depths[SENSOR_TO_DEPTH["start-rt-cent"]]
              )}cm`,
              type: "line",
              symbol: "none",
              connectNulls: true,
              sampling: "min",
              encode: {
                x: "timestamp",
                y: "start-rt-cent",
              },
            },
            {
              name: `(2)${Math.round(
                depths[SENSOR_TO_DEPTH["stop-rt-cent"]]
              )}cm`,
              type: "line",
              symbol: "none",
              connectNulls: true,
              sampling: "min",
              encode: {
                x: "timestamp",
                y: "stop-rt-cent",
              },
            },
            {
              name: t("irrigation"),
              type: "bar",
              barWidth: "6",
              yAxisIndex:
                irrigationHistory &&
                irrigationHistory.irrigation &&
                irrigationHistory.irrigation.columns.includes(
                  "irrigationVolume"
                )
                  ? 2
                  : 1,
              encode: {
                x: "timestamp",
                y: "irrigation",
              },
              tooltip: {
                trigger: "item",
              },
            },
          ],
        }}
      />
    );
  } else {
    return <AppLoader />;
  }
};

export default VirtualDeviceWpGraph;
